import React from "react"
import PropTypes from "prop-types"

import Navbar from "../Navbar/Navbar"
import Footer from "../Footer/Footer"

const SimpleLayout = ({ children, path }) => (
  <>
    <Navbar path={path} />
    {children}
    <Footer />
  </>
)

SimpleLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default SimpleLayout
