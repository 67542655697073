import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faPhone,
  faEnvelope,
  faPlus,
  faBars,
} from "@fortawesome/free-solid-svg-icons"

import styles from "./styles.module.css"
import { Link } from "gatsby"

const isActive = (path, url) =>
  path && url === "/"
    ? path === url
      ? styles.active
      : ""
    : path.includes(url)
    ? styles.active
    : ""

const MobileNavbar = ({ path }) => {
  const [open, setOpen] = useState(false)

  return (
    <div className={`${styles.container} ${styles.mobile} `}>
      <div className={styles.row}>
        <div className={styles.logo}>
          <img alt="icon" src={require("../../images/logo.png")} />
        </div>
        {/* eslint-disable-next-line */}
        <div
          className={styles.button}
          onClick={() => setOpen(true)}
          onKeyPress={() => {}}
        >
          <FontAwesomeIcon icon={faBars} />
        </div>
      </div>
      <div className={`${styles.menu}  ${open ? styles.open : ""}`}>
        <div className={styles.row}>
          <div className={styles.logo}>
            <img alt="icon" src={require("../../images/logo.png")} />
          </div>
          {/* eslint-disable-next-line */}
          <div
            className={styles.button}
            onClick={() => setOpen(false)}
            onKeyPress={() => {}}
          >
            <FontAwesomeIcon icon={faPlus} />
          </div>
        </div>
        <div className={styles.col}>
          <Link
            onClick={() => setOpen(false)}
            className={`${styles.link} ${isActive(path, "/")}`}
            to="/"
          >
            About
          </Link>
          <Link
            onClick={() => setOpen(false)}
            className={`${styles.link} ${isActive(path, "/faqs")}`}
            to="/faqs"
          >
            FAQs
          </Link>
          <Link
            onClick={() => setOpen(false)}
            className={`${styles.link} ${isActive(path, "/portfolio") ||
              isActive(path, "/projects")}`}
            to="/portfolio"
          >
            Portfolio
          </Link>
          <Link
            onClick={() => setOpen(false)}
            className={`${styles.link} ${isActive(path, "/contacts")}`}
            to="/contacts"
          >
            Contacts
          </Link>
        </div>
        <div className={styles.col}>
          <a href="mailto:geral@leaplane.io" className={styles.contact}>
            <FontAwesomeIcon icon={faEnvelope} />
            <p>geral@leaplane.io</p>
          </a>
          <a href="tel:+351919030468" className={styles.contact}>
            <FontAwesomeIcon icon={faPhone} />
            <p>+351 919 030 468</p>
          </a>
          <div className={`${styles.row} ${styles.social}`}>
            <a
              href="https://www.facebook.com/leaplanetech"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={require("../../images/facebook-square.png")}
                alt="Facebook"
              />
            </a>
            <a
              href="https://www.linkedin.com/company/leaplane"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={require("../../images/linkedin.png")} alt="Linkedin" />
            </a>
            <a href="">
              <img
                src={require("../../images/instagram.png")}
                alt="Instagram"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MobileNavbar
